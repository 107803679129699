<template>
  <b-modal
    id="modal-advertisement"
    ref="refModal"
    title="Quảng cáo"
    ok-title="Lưu"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    scrollable
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <b-row>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="Loại hình quảng cáo"
              rules="required"
            >

              <select-advertisement-type
                v-model="itemLocal.type"
                :default-value="itemLocal.type"
                label="Loại hình quảng cáo"
                required
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
          <b-col cols="6">
            <b-form-group>
              <template v-slot:label>
                Thứ tự hiển thị <span class="text-danger">(*)</span>
              </template>
              <spin-button
                v-model="itemLocal.order"
                :min="1"
                :step="1"
                :default-value="itemLocal.order"
              />
            </b-form-group>

          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="title"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Tiêu đề <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="title"
                  v-model="itemLocal.title"
                  placeholder="Quảng cáo VTelehealth"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Phone -->
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="url"
              rules=""
            >
              <b-form-group>
                <template v-slot:label>
                  Đường dẫn (Khi ấn vào hình ảnh sẽ đi đến url này)
                </template>
                <b-form-input
                  id="url"
                  v-model="itemLocal.url"
                  placeholder="https://www.youtube.com/watch?v=YKHLP0UHLHE&t=8s"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Phone -->
          <b-col
            v-if="itemLocal.type && (itemLocal.type.value === 'youtube' || itemLocal.type === 'youtube')"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="s_youtube"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Youtube URL <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="s_youtube"
                  v-model="itemLocal.s_youtube"
                  placeholder="https://www.youtube.com/watch?v=YKHLP0UHLHE&t=8s"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <!-- <avatar-view align="center" /> -->
            <image-view
              v-if="itemLocal.type && (itemLocal.type.value === 'image' || itemLocal.type === 'image')"
              v-model="itemLocal.source_link"
              align="left"
              :src="itemLocal.source_link"
              :width="200"
              :height="120"
              label="Hình ảnh hiển thị"
            />
            <image-view
              v-else
              v-model="itemLocal.image"
              align="left"
              :src="itemLocal.image"
              :width="200"
              :height="120"
              label="Hình ảnh hiển thị"
            />
          </b-col>
          <b-col
            cols="12"
            class="mt-2"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="itemLocal.status"
                name="check-button"
                switch
                inline
              >
                Hiển thị?
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- submit -->
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SpinButton from '@/views/components/SpinButton.vue';
import ImageView from '@/views/components/ImageView.vue';
import { togglePasswordConfirmVisibility } from '@/layouts/mixins/forms';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import SelectAdvertisementType from '@/views/components/SelectAdvertisementType.vue';
import useAdvertisementModal from './useAdvertisementModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    SpinButton,

    ImageView,
    SelectAdvertisementType,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmVisibility],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useAdvertisementModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
