/* eslint-disable camelcase */
import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from "@/utils/toast";
import advertisementStoreModule from '../advertisementStoreModule';

export default function useAdvertisementModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'advertisement';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, advertisementStoreModule);
  }

  // Use toast
  const toastification = toast();
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const {
      type, order, title, url, s_youtube, source_link, image, status,
    } = itemLocal.value;

    const data = {
      order, title, url, s_youtube, source_link, image, status,
    };

    if (type && type.value) {
      data.type = type.value;
    } else {
      data.type = type;
    }

    if (itemLocal.value._id) {
      data._id = itemLocal.value._id;
      store
        .dispatch('advertisement/updateAdvertisement', data)
        .then(() => { })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    } else {
      store
        .dispatch('advertisement/createAdvertisement', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
