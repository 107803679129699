import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAdvertisements(ctx, data) {
      return useJwt.getAdvertisements(data).then(response => response);
    },
    createAdvertisement(ctx, data) {
      return useJwt.createAdvetisement(data).then(response => response);
    },
    updateAdvertisement(ctx, data) {
      return useJwt.updateAdvetisement(data).then(response => response);
    },
    deleteAdvertisement(ctx, id) {
      return useJwt.deleteAdvetisement(id).then(response => response);
    },
  },
};
